import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";

import { Modal, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumberWithError from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import moment from "moment-timezone";
import { useGoogleLogin } from "@react-oauth/google";

import Text from "src/modules/parent/components/text";
import CustomStepper from "./components/stepper";
import FooterComponent from "./components/footer";
import AuthenticationComponent from "./components/steps/stepNo1";
import RegistrationComponent from "./components/steps/stepNo2";
import StudentRegistration from "./components/steps/stepNo3";
import InEligibleModal from "src/modules/parent/components/trivia-registration-form/components/modal/ineligible";
import AccountInUse from "./components/modal/accountInUse";
import actionTypes from "../../store/action-types";
import { phoneNumberNotification } from "../../utils/notificationData";

import * as actions from "src/modules/parent/store/actions";

import styles from "./styles.module.css";
import axios from "axios";

const checkGrades = [
  "Grade 3",
  "Grade 4",
  "Grade 5",
  "Grade 6",
  "Grade 7",
  "Grade 8",
];

const TriviaRegistrationForm = ({
  show = false,
  userAlreadyLoggedIn = false,
  setShow = () => {},
  onSuccess = () => {},
}) => {
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = bindActionCreators(actions, dispatch);
  const { userInfo } = useSelector((state) => state.authReducer);
  const { children } = useSelector((state) => state.profileReducer);
  const [step, setStep] = useState(1);
  const [registerationData, setRegisterationData] = useState(null);
  const [studentIds, setStudentIds] = useState(new Set([]));
  const [disabledIds, setDisabledIds] = useState(new Set([]));
  const [isRegistered, setIsRegistered] = useState(userAlreadyLoggedIn);
  const [isChildAdded, setIsChildAdded] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [authId, setAuthId] = useState(null);
  const [showInEligibleModal, setShowInEligibleModal] = useState(false);
  const [showAccountInUse, setShowAccountInUse] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCancel = () => setShow(false);
  const parentRegisterationRef = useRef(null);
  const parentLoginRef = useRef(null);
  const studentRegistrationRef = useRef(null);

  useEffect(() => {
    if (userAlreadyLoggedIn) {
      setUserLoggedIn(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && userInfo && !children?.length) {
      action.getChildren(userInfo.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isAuthenticated]);

  useEffect(() => {
    if (children) {
      setIsRegistered(!!children.length);

      const unmatchedStudents = children.filter(
        (student) => !checkGrades.includes(student.level)
      );

      if (unmatchedStudents.length > 0) {
        setDisabledIds(
          new Set(
            unmatchedStudents.map((student) => {
              return student.id;
            })
          )
        );
      }

      setStep(3);
    }
  }, [children]);

  const handleSubmit = useCallback(
    (values) => {
      const timezone = moment.tz.guess();
      const data = {
        ...registerationData,
        ...values,
        timezone,
      };

      const student_id = Array.from(studentIds);
      const registeredData = {
        timezone,
      };

      if (isRegistered) {
        if (student_id.length > 0) {
          registeredData.student_id = student_id;
        }

        if (values?.students && values?.students?.length) {
          registeredData.students = values?.students;
        }
      }

      const payload = isRegistered ? registeredData : data;
      setLoading(true);

      actions
        .registerTriviaCompetition(payload)
        .then(() => {
          setLoading(false);
          setShow(false);
          onSuccess();

          if (!isRegistered) {
            action.loginWithEmail({ ...data, role: "parent" }, true);
          }
        })
        .catch((error) => {
          if (error === "accountInUse") {
            setShowAccountInUse(true);
          }
          setLoading(false);
        });
    },
    [action, isRegistered, onSuccess, registerationData, setShow, studentIds]
  );

  const handleNext = useCallback(() => {
    if (step) {
      switch (step) {
        case 2:
          const reference =
            authId === "1"
              ? parentLoginRef?.current
              : parentRegisterationRef?.current;
          reference?.handleSubmit();
          break;

        case 3:
          isRegistered
            ? isChildAdded
              ? studentRegistrationRef?.current?.handleSubmit()
              : handleSubmit()
            : studentRegistrationRef?.current?.handleSubmit();
          break;

        default:
          setStep((prev) => prev + 1);
          break;
      }
    }
  }, [authId, handleSubmit, isChildAdded, isRegistered, step]);

  const handleSetRegisterationData = useCallback(
    (data) => {
      const phoneNumber = parsePhoneNumberWithError(data.phone);

      if (phoneNumber && phoneNumber?.isValid()) {
        setRegisterationData((prev) => ({
          ...prev,
          ...data,
          phone: phoneNumber.number,
        }));

        setStep((prev) => prev + 1);
      } else {
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: phoneNumberNotification,
        });
      }
    },
    [dispatch]
  );

  const handleClearStudents = useCallback(() => {
    dispatch({
      type: actionTypes.SET_CHILDREN,
      payload: null,
    });
  }, [dispatch]);

  const handleLogin = useCallback(
    (values) => {
      setLoading(true);

      const data = {
        ...values,
        role: "parent",
      };

      handleClearStudents();
      action
        .loginWithEmail(data, true)
        .then(() => {
          setLoading(false);
        })
        .catch(() => setLoading(false));
    },
    [action, handleClearStudents]
  );

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      handleClearStudents();
      setLoading(true);

      const token = tokenResponse.access_token;
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response?.data) {
        const data = {
          email: response?.data?.email,
          name: response?.data?.name,
          login_type: "google",
        };

        action
          .googleLogin(true, data)
          .then(() => {
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    },
  });

  const handlePrevious = useCallback(() => {
    setStep((prev) => prev - 1);
    setIsRegistered(false);
  }, []);

  const handleSetStudentIds = (id) => {
    setStudentIds((prevIds) => {
      const newIds = new Set(prevIds);
      newIds.has(id) ? newIds.delete(id) : newIds.add(id);
      return newIds;
    });
  };

  const handleValidate = (values) => {
    return setIsChildAdded(!!values?.students?.length);
  };

  const renderSteps = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <AuthenticationComponent authId={authId} handleClick={setAuthId} />
        );

      case 2:
        return (
          <RegistrationComponent
            authId={authId}
            parentRegisterationRef={parentRegisterationRef}
            parentLoginRef={parentLoginRef}
            initialValues={registerationData}
            handleLogin={handleLogin}
            handleGoogleLogin={handleGoogleLogin}
            handleSubmit={handleSetRegisterationData}
          />
        );

      case 3:
        return (
          <StudentRegistration
            formikRef={studentRegistrationRef}
            studentIds={studentIds}
            disabledIds={disabledIds}
            isRegistered={isRegistered}
            handleStudentChange={handleSetStudentIds}
            handleSubmit={handleSubmit}
            handleValidate={handleValidate}
            showInEligibleModal={() => setShowInEligibleModal(true)}
          />
        );

      default:
        break;
    }
  }, [
    authId,
    disabledIds,
    handleGoogleLogin,
    handleLogin,
    handleSetRegisterationData,
    handleSubmit,
    isRegistered,
    registerationData,
    step,
    studentIds,
  ]);

  const isButtonDisabled = useMemo(() => {
    if (!isRegistered) return false;
    if (isChildAdded) return false;
    return studentIds.size <= 0;
  }, [isChildAdded, isRegistered, studentIds]);

  const getButtonEv = useMemo(() => {
    switch (step) {
      case 1:
        return !authId;

      case 2:
        return false;

      case 3:
        return isButtonDisabled;

      default:
        return true;
    }
  }, [authId, isButtonDisabled, step]);

  const handleForgotPassword = useCallback(() => {
    navigate("/reset-password");
  }, [navigate]);

  return (
    <Modal
      open={show}
      onCancel={handleCancel}
      maskClosable
      closable={false}
      footer={false}
      className={styles.modalContainer}
    >
      <div className={"mt-20 mb-20"}>
        <Space
          className={"w-100"}
          align={"center"}
          size={"middle"}
          direction={"vertical"}
        >
          <Text size={"XXL"} font={"SEMIBOLD"} title={"Registration Form"} />

          {!userLoggedIn && (
            <Text
              size={"S"}
              font={"REGULAR"}
              color={"rgba(200, 200, 208, 1)"}
              title={"Please fill out this form to register for Trivia 2025!"}
            />
          )}
        </Space>

        {!userLoggedIn && <CustomStepper currentStep={step} />}

        <div className={styles.steps}>{renderSteps}</div>

        <FooterComponent
          onHandlePrevious={handlePrevious}
          isPrevious={step > 1 && !userLoggedIn}
          isLast={step === 3}
          isLoading={loading}
          isDisabled={getButtonEv}
          onHandleNext={handleNext}
        />
      </div>

      {/* Trivia InEligible Registration */}
      <InEligibleModal
        setShow={setShowInEligibleModal}
        show={showInEligibleModal}
      />

      {/* Account Already Exists */}
      <AccountInUse
        show={showAccountInUse}
        setShow={setShowAccountInUse}
        handleForgotPassword={handleForgotPassword}
      />
    </Modal>
  );
};

export default TriviaRegistrationForm;
