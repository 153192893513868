/* eslint-disable no-unused-vars */
import actionTypes from "../../action-types";
import {
  LOGIN,
  LOGIN_SOCIAL,
  REGISTER_USER,
  RESET_PASSWORD,
  UPDATE_USER_PROFILE,
  USER_PROFILE,
  GET_DASHBOARD_DATA,
  GET_WHATS_NEW,
  GET_UPCOMING_EVENTS,
  GET_TRACKER_DATA,
} from "src/modules/parent/config/api";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "src/modules/parent/config/network";
import {
  axiosErrorHandler,
  getFilteredTimeSlots,
  getNotificationData,
  getStartEndTime,
} from "src/modules/parent/utils";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import loginImg from "src/modules/parent/assets/notificationSvgs/loggedIn.svg";
import {
  accountExistNotification,
  accountInUsenotification,
  facebookNotification,
  logoutNotification,
  renderContent,
} from "src/modules/parent/utils/notificationData";

const loginContent = (name) => (
  <div>{`You are now logged in as ${name} successfully! Happy Learning!`}</div>
);

export function loginWithEmail(logincredentials, isNotify = false) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await postRequest(LOGIN, logincredentials, false);
        const { data } = response?.data;
        const userObj = {
          ...data?.user,
          token: data?.token,
          type: "loginViaEmail",
        };
        dispatch({ type: actionTypes.SET_LOGGED_IN_USER, payload: userObj });
        dispatch({ type: actionTypes.SET_IS_AUTHENTICATED, payload: true });
        resolve(userObj);

        if (!isNotify) {
          const notification = getNotificationData({
            image: loginImg,
            content: () => loginContent(userObj?.name),
            timer: 3000,
          });

          dispatch({
            type: actionTypes.NOTIFICATIONDATA,
            payload: notification,
          });
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function googleLogin(isNotify = false, data) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await postRequest(LOGIN_SOCIAL, data, false);
        if (response?.data?.status === "success") {
          const { data } = response?.data;
          const userObj = {
            ...data?.user,
            token: data?.token,
            type: "google",
          };
          dispatch({ type: actionTypes.SET_LOGGED_IN_USER, payload: userObj });
          dispatch({ type: actionTypes.SET_IS_AUTHENTICATED, payload: true });

          if (!isNotify) {
            const notification = getNotificationData({
              image: loginImg,
              content: () => loginContent(userObj?.name),
              timer: 3000,
            });

            dispatch({
              type: actionTypes.NOTIFICATIONDATA,
              payload: notification,
            });
          }

          resolve();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function facebookLogin(data) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await postRequest(LOGIN_SOCIAL, data, false);
        if (response?.data?.status === "success") {
          const res = response?.data?.data;
          const userObj = {
            ...res?.user,
            token: res?.token,
            type: "facebook",
          };
          dispatch({ type: actionTypes.SET_LOGGED_IN_USER, payload: userObj });
          dispatch({ type: actionTypes.SET_IS_AUTHENTICATED, payload: true });

          const notification = getNotificationData({
            image: loginImg,
            content: () => loginContent(userObj?.name),
            timer: 3000,
          });

          dispatch({
            type: actionTypes.NOTIFICATIONDATA,
            payload: notification,
          });

          resolve();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function registerUser(user) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
      const data = {
        name: user.name,
        phone: user.phone,
        email: user.email,
        password: user.password,
        user_type: user.user_type,
        country: regionNames?.of(
          parsePhoneNumberWithError(user?.phone)?.country
        ),
      };

      try {
        const response = await postRequest(REGISTER_USER, data, false);
        const { user: createdUser, token } = response?.data?.data;
        const userObj = {
          token,
          ...createdUser,
          type: "loginViaEmail",
        };

        dispatch({
          type: actionTypes.SET_LOGGED_IN_USER,
          payload: userObj,
        });
        dispatch({ type: actionTypes.SET_IS_AUTHENTICATED, payload: true });
        dispatch({ type: actionTypes.IS_ONBOARDING, payload: true });
        resolve(userObj);
      } catch (err) {
        reject();

        if (
          err?.response?.data?.message === "The email has already been taken."
        ) {
          return dispatch({
            type: actionTypes.NOTIFICATIONDATA,
            payload: accountInUsenotification,
          });
        }

        axiosErrorHandler(err);
      }
    });
  };
}

export function updateParentProfile(user) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const { data } = await patchRequest(UPDATE_USER_PROFILE, user);
        if (data?.status === "success") {
          dispatch({
            type: actionTypes.UPDATE_PARENT_PROFILE,
            payload: data?.data,
          });

          const notification = getNotificationData({
            type: "success",
            content: () => renderContent(data?.message),
            timer: 3000,
          });

          dispatch({
            type: actionTypes.NOTIFICATIONDATA,
            payload: notification,
          });
          resolve();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function resetPassword(params) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await postRequest(RESET_PASSWORD, params, false);
        if (response?.data?.status === "success") {
          resolve(response?.data?.message);
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function onLogOut() {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      try {
        dispatch({ type: actionTypes.LOG_OUT });

        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: logoutNotification,
        });

        resolve();
      } catch (err) {
        reject();
      }
    });
  };
}

export function fetchParentProfile() {
  return async (dispatch) => {
    try {
      const { data } = await getRequest(USER_PROFILE);
      dispatch({
        type: actionTypes.UPDATE_PARENT_PROFILE,
        payload: data?.data,
      });
    } catch (err) {
      axiosErrorHandler(err);
    }
  };
}

export function checkStudentEnrolments() {
  return async (dispatch) => {
    try {
      const { data } = await getRequest(USER_PROFILE);
      if (data && data.data) {
        const { active_students } = data.data;

        if (active_students) {
          dispatch({
            type: actionTypes.ACTIVE_STUDENTS,
            payload: true,
          });
        } else {
          dispatch({
            type: actionTypes.ACTIVE_STUDENTS,
            payload: false,
          });
        }
      }
    } catch (err) {
      axiosErrorHandler(err);
    }
  };
}

export function updateFromTelenorStatus(value) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_FROM_TELENOR_STATUS, payload: value });
  };
}

export function getDashboardData(childId) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        dispatch({ type: actionTypes.SET_CHILD_ID, payload: childId });
        dispatch({ type: actionTypes.CHILD_LOADING, payload: true });

        const response = await getRequest(`${GET_DASHBOARD_DATA}/${childId}`);
        dispatch({ type: actionTypes.CHILD_LOADING, payload: false });

        if (response?.data?.status) {
          const data = response?.data?.data;
          let arr = [];

          if (
            data &&
            data.recomended_packages &&
            data.recomended_packages.length
          ) {
            data.recomended_packages.forEach((item) => {
              const getTimeSlot = getFilteredTimeSlots(
                item?.time_slots,
                getStartEndTime()
              );

              arr.push({
                ...item,
                time_slots: getTimeSlot,
              });
            });
          }

          dispatch({
            type: actionTypes.SET_DASHBOARD_DATA,
            payload: { ...response?.data?.data, recomended_packages: arr },
          });
          resolve();
        }
      } catch (error) {
        dispatch({ type: actionTypes.CHILD_LOADING, payload: false });
        // axiosErrorHandler(error);
        dispatch({
          type: actionTypes.SET_DASHBOARD_DATA,
          payload: undefined,
        });
        reject();
      }
    });
  };
}

export function getWhatsNewData(childId) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const endpoint = childId
          ? `${GET_WHATS_NEW}?student_id=${childId}`
          : GET_WHATS_NEW;
        dispatch({ type: actionTypes.WHATS_NEW_DATA_LOADING, payload: false });

        const response = await getRequest(endpoint);

        if (response?.data?.status) {
          dispatch({
            type: actionTypes.WHATS_NEW_DATA,
            payload: response?.data?.data,
          });
          resolve();
        }
      } catch (error) {
        dispatch({ type: actionTypes.WHATS_NEW_DATA_LOADING, payload: false });
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function getTrackerData(childId) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await getRequest(`${GET_TRACKER_DATA}/${childId}`);

        if (response?.data?.status) {
          dispatch({
            type: actionTypes.TRACKER_DATA,
            payload: response?.data?.data,
          });
          resolve();
        }
      } catch (error) {
        dispatch({ type: actionTypes.TRACKER_DATA, payload: null });
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function getUpcomingEventsData(childId) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        dispatch({ type: actionTypes.EVENTS_DATA_LOADING, payload: false });

        const response = await getRequest(`${GET_UPCOMING_EVENTS}/${childId}`);

        if (response?.data?.status) {
          dispatch({
            type: actionTypes.EVENTS_DATA,
            payload: response?.data?.data,
          });
          resolve();
        }
      } catch (error) {
        dispatch({ type: actionTypes.EVENTS_DATA_LOADING, payload: false });
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}
