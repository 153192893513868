import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import useFetchAuth from "./checkAuth";
import Header from "../../components/header";
import AppLoader from "../../components/app-loader";

import { AuthenticatedHomePage, UnAuthenticatedHomePage } from "./pages";

import { Modal } from "antd";
import TriviaHomeBanner from "../trivia/components/trivia-home-banner";

const Home = () => {
  const { isAuth, authLoading } = useFetchAuth();
  const { authReducer } = useSelector((state) => state);
  const { isAuthenticated } = authReducer;
  const [isTriviaModalVisible, setIsTriviaModalVisible] = useState(false);

  useEffect(() => {
    setIsTriviaModalVisible(true); // Show modal when component mounts
  }, []);

  const handleCancel = () => {
    setIsTriviaModalVisible(false);
  };

  return (
    <div>
      <Helmet>
        <title>{`Pakistan’s Top Online Learning Platform for Kindergarten to 12th Grade | Dot and Line`}</title>
        <meta
          name="description"
          content={`Pakistans top online tutoring platform with experienced tutors and guaranteed results . Sign up now for academic and professional courses from K-12, including O-Levels, A-Levels. Visit dotandlinelearning.com to begin your educational journey today!`}
        />
      </Helmet>

      {authLoading && (
        <AppLoader
          fullPage
          text={
            authLoading
              ? "Loading..."
              : "You are being logged out. Please login again!"
          }
        />
      )}

      <Header />

      {!authLoading && (
        <>
          {isAuthenticated && isAuth ? (
            <AuthenticatedHomePage />
          ) : (
            <UnAuthenticatedHomePage />
          )}

          <Footer />
        </>
      )}

      <Modal
        open={isTriviaModalVisible}
        footer={null} // Removes OK and Cancel buttons
        closable
        centered
        onCancel={() => handleCancel()}
        className={"triviaModalHome"}
        width={"auto"}
      >
        <TriviaHomeBanner />
      </Modal>
    </div>
  );
};

export default Home;
