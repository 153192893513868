import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "../src/modules/parent/assets/styles/global.scss";
import "react-multi-carousel/lib/styles.css";
import App from "./App";
import "antd/dist/reset.css";
import "react-chat-elements/dist/main.css";
import "./fonts.css";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./modules/parent/store";
import { GOOGLE_CLIENT_ID } from "./modules/parent/config/constant";

const google_client_id = GOOGLE_CLIENT_ID;

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={google_client_id}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <App />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
