import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import { storeFCMToken } from "../store/actions";

const { REACT_APP_WEB_PUSH_KEY } = process.env;
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const webKey = REACT_APP_WEB_PUSH_KEY;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCuu-Y19KNd_WLVxX0oZpo0ZoVTClTyMUo",
  authDomain: "parent-app-dotnline.firebaseapp.com",
  projectId: "parent-app-dotnline",
  storageBucket: "parent-app-dotnline.appspot.com",
  messagingSenderId: "358615968553",
  appId: "1:358615968553:web:c16564a3af7eb165116538",
  measurementId: "G-7GEPKFZRHV",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    const messaging = getMessaging(app);
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const getFcmToken = async () => {
  const messaging = getMessaging(app);
  return await getToken(messaging, {
    vapidKey: webKey,
  });
};

export const requestPermission = async () => {
  const supported = await isSupported();

  if (supported) {
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          getFcmToken().then((token) => {
            if (token) {
              storeFCMToken(token);
            }
          });
        } else {
          console.warn("Notification permission denied.");
        }
      })
      .catch((error) => {
        console.error("Error requesting notification permission:", error);
      });
  }
};

export { app, analytics };
