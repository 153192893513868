/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "antd";
import { useGoogleLogin } from "@react-oauth/google";
import googleBtn from "../../assets/img/g2.png";
import gif from "../../assets/img/svg/gif_image_replica.svg";
import logo from "../../assets/img/dotlinelogowithname.png";
import facebookBtn from "../../assets/img/fb.png";
import * as actions from "../../store/actions";
import CardsWrapper from "src/modules/parent/components/cards-wrapper";
import TextInput from "src/modules/parent/components/text-input";
import "./index.scss";
import FlatButton from "../../components/flat-button";
import { FACEBOOK_CIENT_ID } from "../../config/constant";
import actionTypes from "../../store/action-types";
import { fillRequiredNotification } from "../../utils/notificationData";
import axios from "axios";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = bindActionCreators(actions, dispatch);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState("");

  const isValidated = () => {
    if (!email || !password) {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: fillRequiredNotification,
      });
      setIsError(true);
      return false;
    }
    return true;
  };

  const navigationHandler = () => {
    const [paramKey, paramValue] = window?.location?.search?.split("="); // Getting route params
    if (paramKey === "?redirect_to" && paramValue) {
      navigate(paramValue);
    } else {
      navigate("/");
    }
  };

  const facebookLoginHandler = (response) => {
    if (response?.data) {
      const data = {
        email: response?.data?.email,
        name: response?.data?.name,
        login_type: "facebook",
      };

      action.facebookLogin(data).then(() => {
        navigationHandler();
      });
    }
  };

  const fbLoginFailure = () => {};

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse.access_token;
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response?.data) {
        const data = {
          email: response?.data?.email,
          name: response?.data?.name,
          login_type: "google",
        };

        action.googleLogin(false, data).then(() => {
          navigationHandler();
        });
      }
    },
  });

  const submitHandler = async () => {
    if (isValidated()) {
      setLoading(true);

      const data = {
        email,
        password,
        role: "parent",
      };

      action
        .loginWithEmail(data)
        .then((userObj) => {
          setLoading(false);
          navigationHandler();
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div className={"container auth-form"}>
      <div className={"inner-container w-100"}>
        <div className="logo-div">
          <Link to={"/"}>
            <img src={logo} alt={"brand-logo"} />
          </Link>
        </div>
        <CardsWrapper>
          <Fragment>
            <div className={"form-wrapper"}>
              <div className={"header-text"}>Login</div>
              <Form className="text-align-center">
                <Form.Item
                  hasFeedback={email.length < 1 && isError ? true : false}
                  validateStatus={
                    email.length < 1 && isError ? "error" : "success"
                  }
                >
                  <TextInput
                    required
                    allowClear
                    value={email}
                    type={"email"}
                    label={"Email"}
                    placeholder={"Enter email"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback={password.length < 1 && isError ? true : false}
                  validateStatus={
                    password.length < 1 && isError ? "error" : "success"
                  }
                >
                  <TextInput
                    required
                    allowClear
                    value={password}
                    type={"password"}
                    label={"Password"}
                    placeholder={"Enter password"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <div className="text-align-left">
                    <Link to={"/reset-password"} className={"link-text"}>
                      Forgot password?
                    </Link>
                  </div>
                </Form.Item>
                <div>
                  <FlatButton
                    isForm
                    width={"180px"}
                    title={"Login"}
                    loading={loading}
                    onClick={submitHandler}
                    className={"mt-20 mb-40"}
                  />
                </div>
                <div className="horizontal-line">
                  <hr />
                  <div className="or-txt">OR</div>
                  <hr />
                </div>
                <div className={"social-buttons mt-20 mb-30"}>
                  <FlatButton
                    postIcon={<img src={googleBtn} alt="Icon" />}
                    onClick={googleLoginHandler}
                    className={"g-button mr-15"}
                    width={"50px"}
                  />
                  <FacebookLogin
                    appId={FACEBOOK_CIENT_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={facebookLoginHandler}
                    onFailure={fbLoginFailure}
                    render={(renderProps) => (
                      <FlatButton
                        width={"50px"}
                        className={"fb-button"}
                        postIcon={<img src={facebookBtn} alt="Icon" />}
                        onClick={renderProps.onClick}
                      />
                    )}
                  />
                </div>
                <div>
                  <p className="white-color mb-0 font-weight-300">
                    Don’t have a dot & line account?{" "}
                    <a
                      onClick={() => navigate("/signup")}
                      className={"link-text"}
                    >
                      Signup
                    </a>
                  </p>
                  <p className="white-color mb-0 font-weight-300">
                    <em>Dot and Line Inc. is incorporated in Delaware, US</em>
                  </p>
                </div>
                <div className={"links"}>
                  <Link
                    target={"_blank"}
                    to={"/privacy-and-policy"}
                    className={"link-text link-text1"}
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    target={"_blank"}
                    to={"/terms-and-conditions"}
                    className={"link-text link-text2"}
                  >
                    Terms and Conditions
                  </Link>
                  <Link target={"_blank"} to={"/faqs"} className={"link-text"}>
                    FAQs
                  </Link>
                </div>
              </Form>
            </div>
            <div className={"image-wrapper"}>
              <img src={gif} alt="GIF" />
            </div>
          </Fragment>
        </CardsWrapper>
      </div>
    </div>
  );
};

export default Login;
